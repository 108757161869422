// eslint-disable-next-line
import { useEffect, useState } from "react";
import raribleService from "../../services/rarible.service";
import ItemTable from "./components/table";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Listing(props) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [unboxItems, setUnboxItems] = useState([]);
  let prevFilter = JSON.parse(localStorage.getItem("prevFilter"));
  if (!prevFilter) {
    prevFilter = [
      {
        type: "filter",
        option: "traits",
        key: "Genesis",
        value: "Yes",
      },
      {
        type: "sort",
        option: "traitSort",
        key: "Current Efficiency",
        value: "DESC-NUMERIC",
      },
      {
        type: "filter",
        option: "traits",
        key: "Rarity",
        value: "COMMON",
      },
    ];
  }
  const [filter, setFilter] = useState(prevFilter);
  const [sorts, setSorts] = useState({});
  const [loadingData, setLoadData] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  let loading = false;
  const [tableRows, setTableRows] = useState(raribleService.tableRows);
  const [searchParams, setSearchParams] = useSearchParams();
  const [initail, setInitail] = useState(true);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    let _sort = {};
    let _selected = {};
    for (let i = 0; i < filter.length; i++) {
      const o = filter[i];
      if (o.type === "filter") {
        _selected[o.key] = o.value;
      }
      if (o.type === "sort") {
        _sort = o;
      }
    }
    setSorts(_sort);

    const tableRows = [...raribleService.tableRows];
    if (_sort?.key === "Current Efficiency" && _selected.Genesis === "Yes") {
      const rows = tableRows.sort(
        (a, b) => a.currentEffeciencyGenesisYes - b.currentEffeciencyGenesisYes
      );
      setTableRows((prev) => rows);
    } else if (
      _sort?.key === "Current Efficiency" &&
      _selected.Genesis === "No"
    ) {
      const rows = tableRows.sort(
        (a, b) => a.currentEffeciencyGenesisNo - b.currentEffeciencyGenesisNo
      );
      setTableRows((prev) => rows);
    } else if (
      _sort?.key === "Current Quality" &&
      _selected.Genesis === "Yes"
    ) {
      const rows = tableRows.sort(
        (a, b) => a.currentQualityGenesisYes - b.currentQualityGenesisYes
      );
      setTableRows((prev) => rows);
    } else if (_sort?.key === "Current Quality" && _selected.Genesis === "No") {
      const rows = tableRows.sort(
        (a, b) => a.currentQualityGenesisNo - b.currentQualityGenesisNo
      );
      setTableRows((prev) => rows);
    } else if (_sort?.key === "Current Luck") {
      const rows = tableRows.sort((a, b) => a.currentLuck - b.currentLuck);
      setTableRows((prev) => rows);
    } else if (
      _selected?.Box === "Unbox" &&
      !_selected.Rarity &&
      !["Current Quality", "Current Efficiency", "Current Luck"].includes(
        _sort?.key
      )
    ) {
      const rows = tableRows.filter((o) => o.unboxItem);
      setTableRows((prev) => rows);
    } else {
      setTableRows((prev) => tableRows);
    }
  }, [filter]);

  async function fetchData(next = null) {
    if (loading) return;
    setLoadData(true);
    loading = true;

    const params = Object.fromEntries(searchParams.entries());
    let filters = [];
    if (Object.entries(params).length > 0) {
      for (let [key, value] of Object.entries(params)) {
        const filter = raribleService.filterToObject(key, value);
        if (!filter) {
          continue;
        }
        filters.push(filter);
      }
    } else if (initail) {
      filters = filter;
    }
    setFilter(filters);
    setInitail(false);

    let initialFilter = raribleService.initialFilter;
    delete initialFilter.continuation;
    delete initialFilter.sort;
    delete initialFilter.traitSort;
    delete initialFilter.filter.traits;
    delete initialFilter.filter.traitRanges;

    let traits = [];
    let traitRanges = [];
    let traitSort = null;
    let sort = null;
    let traitStatic = null;

    for (let i = 0; i < filters.length; i++) {
      const obj = filters[i];
      if (obj.type === "filter") {
        if (obj.option === "traits") {
          const index = traits.findIndex((o) => o.key === obj.key);
          if (index > -1) {
            if (traits[index].value) {
              traits[index].values = [traits[index].value];
              delete traits[index].value;
            }
            traits[index].values.push(obj.value);
          } else {
            traits.push({
              key: obj.key,
              value: obj.value,
            });
          }
        } else if (obj.option === "traitRanges") {
          traitRanges.push({
            key: obj.key,
            valueRange: {
              from: Number(obj.value.split("-")["0"]),
              to: Number(obj.value.split("-")["1"]),
            },
          });
        } else if (obj.option === "traitStatic") {
          traitStatic = obj;
        }
      } else if (obj.type === "sort") {
        if (obj.option === "traitSort") {
          traitSort = {
            order: obj.value.split("-")["0"],
            type: obj.value.split("-")["1"],
            key: obj.key,
          };
        } else if (obj.option === "sort") {
          sort = obj.value;
        }
      }
    }

    let _unboxItems = unboxItems;
    if (_unboxItems.length === 0 && filters.find((o) => o.key === "Box")) {
      const _data = await raribleService.searchNfts(initialFilter);
      _unboxItems = await raribleService.unboxItems(_data?.items ?? []);
    }

    if (traits.length > 0) {
      initialFilter.filter.traits = traits;
    }

    if (traitRanges.length > 0) {
      initialFilter.filter.traitRanges = traitRanges;
    }

    if (traitSort) {
      initialFilter.traitSort = traitSort;
      initialFilter.sort = "TRAIT";
    } else if (sort) {
      initialFilter.sort = sort;
    }

    if (next) {
      initialFilter.continuation = next;
    }

    const data = await raribleService.searchNfts(initialFilter);
    let _items = items;
    if (next) {
      _items = _items.concat(data?.items ?? []);
    } else {
      _items = data?.items ?? [];
    }

    // hidden unbox items
    _items = await raribleService.hideUnboxItems(_items);

    if (traitStatic && traitStatic.key === "Box") {
      if (traits.filter((o) => o.key === "Rarity").length > 0) {
        _items = _items.concat(_unboxItems);
      } else {
        _items = _unboxItems;
      }
      setUnboxItems(_unboxItems);
      const traitGen = traits.find(o => o.key === "Genesis");
      if(traitGen && traitGen.value){
        _items = await raribleService.filterItems(_items, "Genesis", traitGen.value);
      }
    }

    if (
      (sort && traitStatic?.key === "Box") || 
      (traitSort &&
      (traitSort.key === "Condition" ||
        traitSort.key === "Mint" ||
        traitSort.key === "Rarity"))
    ) {
      const sortItems = await raribleService.sortAttributes(_items, traitSort, sort);
      setItems((prev) => sortItems);
    } else {
      setItems((prev) => _items);
    }

    if (data?.items?.length === initialFilter.size) {
      setNextPage((prev) => data?.continuation);
    } else {
      setNextPage((prev) => null);
    }

    loading = false;
    setLoadData(false);
  }

  function onFilter(obj, isSort = false) {
    if (loadingData) return;
    let _filter = filter;

    if (activeFilter(obj.key, obj.value) && !isSort) {
      _filter = _filter.filter(
        (o) => !(o.key === obj.key && o.value === obj.value)
      );
    } else {
      if (obj.type === "sort") {
        const index = _filter.findIndex((o) => o.type === "sort");
        if (index > -1) {
          _filter.splice(index, 1);
        }
      }
      _filter.push(obj);
    }

    if(_filter.find(o => o.key === "Box") && !_filter.find(o => o.key === "Rarity")){
      _filter = _filter.filter(
        (o) => !(["Current Quality", "Current Efficiency", "Current Luck"].includes(o.key))
      );
    }

    if (!_filter.find(o => ["Current Quality", "Current Efficiency", "Current Luck", "Rarity"].includes(o.key)) &&
      _filter.find(o => o.key === "Box")
      && !isSort
    ) {
      const index = _filter.findIndex((o) => o.type === "sort");
      if (index > -1) {
        _filter.splice(index, 1);
      }
      _filter.push({
        key: "Price",
        type: "sort",
        option: "sort",
        value: "LOWEST_SELL",
      });
    }

    localStorage.setItem("prevFilter", JSON.stringify(_filter));

    let query = {};
    for (let i = 0; i < _filter?.length; i++) {
      const obj = _filter[i];
      query[`filter[${obj.type}][${obj.option}][${obj.key}][value][${i}]`] =
        obj.value;
    }
    setSearchParams((prev) => query);
    setNextPage(null);

    if (
      [
        "Genesis",
        "Current Quality",
        "Current Efficiency",
        "Current Luck",
      ].includes(obj.key)
    ) {
      setScrollLeft((prev) => prev + 1);
    }
  }

  function activeFilter(key, value) {
    return filter.find((o) => o.key === key && o.value === value);
  }

  return (
    <>
      <div class="l-content">
        <ul class="l-heading l-heading__nav">
          <li class="active">{t("Camera NFT")}</li>
          <li><Link to="/mint-scroll">{t("Mint Scroll")}</Link></li>
        </ul>
        <div class="p-filter">
          {raribleService.filterOptions.map((options, index) => {
            return (
              <div
                class={`p-filter__inner  ${
                  index === 1 && "p-filter__inner__rarity"
                }`}
                key={index}
              >
                {options.map((option, key) => {
                  return (
                    <ul class={option.ul_class} key={key}>
                      {option.items.map((item, key) => {
                        return (
                          <li
                            class={`${item.li_class} ${
                              activeFilter(item.key, item.value) ? "active" : ""
                            }`}
                            key={key}
                            onClick={() => {
                              onFilter(item);
                            }}
                          >
                            {item.name_ja}
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div class="p-filter__result">
          <p>
            <span>{t("NFTs that fit the criteria")}：</span>
            {items.length}
          </p>
        </div>
      </div>
      <div class="p-list__wrapper">
        <ItemTable
          scrollLeft={scrollLeft}
          items={items}
          rows={tableRows}
          onFilter={onFilter}
          sorts={sorts}
          loading={loadingData}
          nextPage={nextPage}
          loadMore={(page) => {
            if (!page) return;
            fetchData(page);
          }}
        />
        <p>
          [{t("Disclaimer")}]
          <br />
          {t("Disclaimer Description")}
        </p>
      </div>
      {loadingData && (
        <div class="overlay">
          <div class="loader"></div>
        </div>
      )}
    </>
  );
}
