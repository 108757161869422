export default function Footer(props) {

    return (<>
        <footer>
            <ul class="l-footer__logo">
                <li>
                    <a href="https://lp.snpit.xyz/" target="_blank" rel="noreferrer"><img src="./assets/images/lo_snpit.png" alt="SNPIT" /></a>
                </li>
                <li>
                    <a href="https://rarible.com/snpit/items" target="_blank" rel="noreferrer" ><img src="./assets/images/lo_rarible.png" alt="Rarible" /></a>
                </li>
            </ul>
            <p>
                <a href="https://x.com/NFTTmarket" target="_blank" rel="noreferrer"><img src="./assets/images/lo_x.png" alt="X" /></a>
            </p>
            {/* <ul class="l-footer__nav">
					<li><a href="#">利用規約</a></li>
					<li><a href="#">プライバシーポリシー</a></li>
				</ul> */}
            <address>©️ 2024 NFTT.market</address>
        </footer>
    </>)
}