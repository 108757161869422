export function Header(props) {

    return (<>
        <header>
            <div class="l-header-l">
                <h1>
                    <a href="/"><img src="/assets/images/lo_nftt.png" alt="NFTT α" /></a>
                </h1>
                <a href="/"><img src="/assets/images/lo_snpit.png" alt="SNPIT" /></a>
            </div>
            <div class="l-header-r">
                <i class="fa-solid fa-globe"></i>
                <div class="l-global__button">
                    <div class="l-toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    </>)
}