import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./reducers";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Master from "./components/layouts/master";
import Page404 from "./modules/errors/404";
// import Top from "./modules/top";
import Listing from "./modules/listing";
import MintScroll from "./modules/mint-scroll";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Master />,
    children: [
      // {
      //   path: "",
      //   element: <Top />,
      // },
      {
        path: "",
        element: <Listing />,
      },
      {
        path: "/mint-scroll",
        element: <MintScroll />,
      },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router}/>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
