import { useTranslation } from "react-i18next";
import raribleService from "../../../services/rarible-v2.service";
import { useEffect, useRef } from "react";

export default function ItemTable(props) {
    const { items, rows, sorts, nextPage } = props;
    const { t } = useTranslation();

    const tableRef = useRef(null);

    let scrollBefore = 0;

    const loadMore = () => {
        props.loadMore(nextPage);
    }

    const onScroll = () => {
        try {
            const scrolled = Math.ceil(
                window.innerHeight + window.scrollY,
            );
            if (
                scrolled > scrollBefore &&
                scrolled >= Math.floor(document.documentElement.scrollHeight)
            ) {
                scrollBefore = scrolled;
                loadMore();
            }
        } catch (error) {
            console.error("error", error);
        }

    };

    useEffect(() => {
        document.addEventListener("scroll", onScroll);

        return () => {
            document.removeEventListener("scroll", onScroll);
            // eslint-disable-next-line
            scrollBefore = 0;
        };
    }, [nextPage]);


    function attributeValue(attr, key) {
        const obj = {};
        for (let i = 0; i < attr.length; i++) {
            const o = attr[i];
            obj[o.key] = o.value;
        }

        if (key === "Rarity") {
            return (
                <img
                    src={`/assets/images/ic_${obj[key]?.toLowerCase()}.png`}
                    alt={obj[key]?.toLowerCase()}
                />
            );
        }

        return obj[key];
    }

    function bodyRow(key, item, activeClass = "") {
        const { meta, bestSellOrder } = item;
        switch (key) {

            case "NFT":
                return (
                    <td class={`${activeClass} td__sticky`}>
                        <div class="td__inner">
                            <img src={meta.content[0].url} alt={`#${item.tokenId}`} />
                            <div className="nft_name">
                                <span className="nft_id">{meta.name?.split("#")[1]}</span>
                                <small class={`genesis ${raribleService.attributeVal(meta.attributes, "Genesis")}`}>
                                    {attributeValue(meta.attributes, "Genesis")}
                                </small>
                            </div>
                        </div>
                    </td>
                );
            case "Price":
                return (
                    <td class={activeClass}>
                        <div class="td__inner td__price">
                            <div>
                                {Number(bestSellOrder?.makePrice)?.toLocaleString()}
                            </div>
                        </div>
                    </td>
                );
            case "Rarity":
                return (
                    <td class={activeClass}
                        style={{
                            verticalAlign: 'middle'
                        }}
                    >
                        {attributeValue(meta.attributes, "Rarity")}
                    </td>
                );
            case "Details":
                return (
                    <td
                        style={{
                            verticalAlign: 'middle'
                        }}
                    >
                        <img src="/assets/images/ic_rarible.png" alt="Rarible" />
                    </td>
                );
            default:
                return <td></td>;
        }
    }

    function onSort(row, sorts) {
        if (!row.sortKey) return;
        let sort = row.sort;
        if (activeSort(row, sorts)) {
            if (row.key === "Price") {
                if (sorts.value === "LOWEST_SELL") {
                    sort.value = "HIGHEST_SELL";
                } else {
                    sort.value = "LOWEST_SELL";
                }
            } else {
                let sortValue = sorts.value.split("-");
                if (sortValue[0] === "DESC") {
                    sortValue[0] = "ASC";
                } else {
                    sortValue[0] = "DESC";
                }
                sort.value = sortValue.join("-");
            }
        }
        props.onFilter({
            key: row.key,
            ...sort,
        }, true);
    }

    function onLink(item) {
        const uriPath = item.id.split(":");
        window.open(
            raribleService.siteUrl +
            "/token/" +
            uriPath[0].toLowerCase() +
            "/" +
            uriPath[1] +
            ":" +
            uriPath[2]
        );
    }

    function activeSort(row, sorts) {

        return sorts && sorts?.key === row.key;
    }

    if (items.length === 0) {
        return (<></>);
    }

    return (
        <div class="p-list__wrapper2" ref={tableRef}>
            <table>
                <thead
                    style={{
                        position: "sticky",
                        top: 0,
                        background: "#141919",
                        zIndex: 1,
                    }}>
                    <tr>
                        {rows.map((row, key) => {
                            return (
                                <th
                                    class={`${row.key === "NFT" && "td__sticky"} ${sorts && sorts?.key === row.key && "td__sort__active"
                                        } th-${row.key}`}
                                    onClick={() => onSort(row, sorts)}
                                    key={key}
                                >
                                    <div className="td__sort">
                                        <div dangerouslySetInnerHTML={{ __html: row.name_ja }} />{" "}
                                        {row.sortKey ? (
                                            <>
                                                {row.key === "Price" ? (
                                                    activeSort(row, sorts) ?
                                                        (
                                                            sorts.value === "LOWEST_SELL" ?
                                                                <i class="fa-solid fa-square-caret-up on"></i>
                                                                : <i class="fa-solid fa-square-caret-down on"></i>
                                                        )

                                                        : <i class="fa-solid fa-square-caret-down"></i>
                                                ) : (
                                                    activeSort(row, sorts) ?
                                                        (
                                                            sorts.value.split("-")[0] === "DESC" ?
                                                                <i class="fa-solid fa-square-caret-down on"></i>
                                                                : <i class="fa-solid fa-square-caret-up on"></i>
                                                        )
                                                        : <i class="fa-solid fa-square-caret-up"></i>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, key) => {
                        return (
                            <tr key={key} onClick={() => onLink(item)}>
                                {rows.map((row) => {
                                    const activeClass = activeSort(row, sorts) ? "td__sort__active" : "";
                                    return bodyRow(row.key, item, activeClass);
                                })}
                            </tr>
                        );
                    })}
                    {items.length === 0 && (
                        <tr>
                            <td>{t("No NFTs found")}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
