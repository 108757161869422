import { useEffect, useState } from "react";
import raribleService from "../../services/rarible-v2.service";
import ItemTable from "./components/table";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MintScroll(props) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  let prevFilter = JSON.parse(localStorage.getItem("prevMintScrollFilter"));
  if (!prevFilter) {
    prevFilter = [
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "COMMON",
      },
      {
        key: "Price",
        type: "sort",
        option: "sort",
        value: "LOWEST_SELL",
      },
    ];
  }
  const [filter, setFilter] = useState(prevFilter);
  const [sorts, setSorts] = useState({});
  const [loadingData, setLoadData] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  let loading = false;
  // eslint-disable-next-line
  const [tableRows, setTableRows] = useState(raribleService.tableRows);
  const [searchParams, setSearchParams] = useSearchParams();
  const [initail, setInitail] = useState(true);


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    let _sort = {};
    let _selected = {};
    for (let i = 0; i < filter.length; i++) {
      const o = filter[i];
      if (o.type === "filter") {
        _selected[o.key] = o.value;
      }
      if (o.type === "sort") {
        _sort = o;
      }
    }
    setSorts(_sort);
  }, [filter]);

  async function fetchData(next = null) {
    if (loading) return;
    setLoadData(true);
    loading = true;

    const params = Object.fromEntries(searchParams.entries());
    let filters = [];
    if (Object.entries(params).length > 0) {
      for (let [key, value] of Object.entries(params)) {
        const filter = raribleService.filterToObject(key, value);
        if (!filter) {
          continue;
        }
        filters.push(filter);
      }
    } else if (initail) {
      filters = filter;
    }
    setFilter(filters);
    setInitail(false);

    let initialFilter = raribleService.initialFilter;
    delete initialFilter.continuation;
    delete initialFilter.sort;
    delete initialFilter.traitSort;
    delete initialFilter.filter.traits;
    delete initialFilter.filter.traitRanges;

    let traits = [];
    let traitRanges = [];
    let traitSort = null;
    let sort = null;

    for (let i = 0; i < filters.length; i++) {
      const obj = filters[i];
      if (obj.type === "filter") {
        if (obj.option === "traits") {
          const index = traits.findIndex((o) => o.key === obj.key);
          if (index > -1) {
            if (traits[index].value) {
              traits[index].values = [traits[index].value];
              delete traits[index].value;
            }
            traits[index].values.push(obj.value);
          } else {
            traits.push({
              key: obj.key,
              value: obj.value,
            });
          }
        } else if (obj.option === "traitRanges") {
          traitRanges.push({
            key: obj.key,
            valueRange: {
              from: Number(obj.value.split("-")["0"]),
              to: Number(obj.value.split("-")["1"]),
            },
          });
        }
      } else if (obj.type === "sort") {
        if (obj.option === "traitSort") {
          traitSort = {
            order: obj.value.split("-")["0"],
            type: obj.value.split("-")["1"],
            key: obj.key,
          };
        } else if (obj.option === "sort") {
          sort = obj.value;
        }
      }
    }

    if (traits.length > 0) {
      initialFilter.filter.traits = traits;
    }

    if (traitRanges.length > 0) {
      initialFilter.filter.traitRanges = traitRanges;
    }

    if (traitSort) {
      initialFilter.traitSort = traitSort;
      initialFilter.sort = "TRAIT";
    } else if (sort) {
      initialFilter.sort = sort;
    }

    if (next) {
      initialFilter.continuation = next;
    }

    const data = await raribleService.searchNfts(initialFilter);
    let _items = items;
    if (next) {
      _items = _items.concat(data?.items ?? []);
    } else {
      _items = data?.items ?? [];
    }
    setItems((prev) => _items);

    if (data?.items?.length === initialFilter.size) {
      setNextPage((prev) => data?.continuation);
    } else {
      setNextPage((prev) => null);
    }

    loading = false;
    setLoadData(false);
  }

  function onFilter(obj, isSort = false) {
    if (loadingData) return;
    let _filter = filter;

    if (activeFilter(obj.key, obj.value) && !isSort) {
      _filter = _filter.filter(
        (o) => !(o.key === obj.key && o.value === obj.value)
      );
    } else {
      if (obj.type === "sort") {
        const index = _filter.findIndex((o) => o.type === "sort");
        if (index > -1) {
          _filter.splice(index, 1);
        }
      }
      _filter.push(obj);
    }

    localStorage.setItem("prevMintScrollFilter", JSON.stringify(_filter));

    let query = {};
    for (let i = 0; i < _filter?.length; i++) {
      const obj = _filter[i];
      query[`filter[${obj.type}][${obj.option}][${obj.key}][value][${i}]`] =
        obj.value;
    }
    setSearchParams((prev) => query);
    setNextPage(null);
  }

  function activeFilter(key, value) {
    return filter.find((o) => o.key === key && o.value === value);
  }

  return (
    <>
      <div class="l-content">
        <ul class="l-heading l-heading__nav">
          <li><Link to="/">{t("Camera NFT")}</Link></li>
          <li class="active">{t("Mint Scroll")}</li>
        </ul>
        <div class="p-filter">
          {raribleService.filterOptions.map((options, index) => {
            return (
              <div
                class={`p-filter__inner p-filter__inner__rarity`}
                key={index}
              >
                {options.map((option, key) => {
                  return (
                    <ul class={option.ul_class} key={key}>
                      {option.items.map((item, key) => {
                        return (
                          <li
                            class={`${item.li_class} ${activeFilter(item.key, item.value) ? "active" : ""
                              }`}
                            key={key}
                            onClick={() => {
                              onFilter(item);
                            }}
                          >
                            {item.name_ja}
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div class="p-filter__result">
          <p>
            <span>{t("NFTs that fit the criteria")}：</span>
            {items.length}
          </p>
        </div>
      </div>
      <div class="p-list__wrapper">
        <ItemTable
          items={items}
          rows={tableRows}
          onFilter={onFilter}
          sorts={sorts}
          loading={loadingData}
          nextPage={nextPage}
          loadMore={(page) => {
            if (!page) return;
            fetchData(page);
          }}
        />
        <p>
          [{t("Disclaimer")}]
          <br />
          {t("Disclaimer Description")}
        </p>
      </div>
      {loadingData && (
        <div class="overlay">
          <div class="loader"></div>
        </div>
      )}
    </>
  );
}
