const raribleApiKey = process.env.REACT_APP_RARIBLE_API_KEY;
const mintScrollAddress = process.env.REACT_APP_MINT_SCROLL_ADDRESS;

const initialFilter = {
  size: 1000,
  filter: {
    deleted: false,
    collections: [`POLYGON:${mintScrollAddress}`],
    // traits: [
    //   {
    //     key: "Current Efficiency",
    //     value: "99",
    //   },
    // ],
    sellPlatforms: ["RARIBLE"],
  },
  //   traitSort: {
  //     order: "ASC",
  //     type: "NUMERIC",
  //     key: "Current Efficiency",
  //   },
};

async function searchNfts(payload = initialFilter) {
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "X-API-KEY": raribleApiKey,
    },
    body: JSON.stringify(payload),
  };

  return await fetch("https://api.rarible.org/v0.1/items/search", options)
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => console.error(err));
}

function filterToObject(str, value) {
  const parts = str.split("[");
  let currentObj = {};
  const filterKeys = ["type", "option", "key", "fieldType", "value"];

  parts.forEach((part, index) => {
    // eslint-disable-next-line
    const [key, _] = part.split("]");
    if (index > 0 && filterKeys.length >= index) {
      currentObj[filterKeys[index - 1]] = key;
    }
  });
  if (currentObj.fieldType === "key") {
    return null;
  }
  currentObj["value"] = value;

  return currentObj;
}

const filterOptions = [
  [
    {
      ul_class: "p-filter--rarity",
      items: [
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "COMMON",
          name_en: "-",
          name_ja: "コモン",
          li_class: "p-filter--common",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "UNCOMMON",
          name_en: "-",
          name_ja: "アンコモン",
          li_class: "p-filter--uncommon",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "RARE",
          name_en: "-",
          name_ja: "レア",
          li_class: "p-filter--rare",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "EPIC",
          name_en: "-",
          name_ja: "エピック",
          li_class: "p-filter--epic",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "LEGENDARY",
          name_en: "-",
          name_ja: "レジェンダリー",
          li_class: "p-filter--legendary",
        },
      ],
    },
  ],
];

const tableRows = [
  {
    name_en: "-",
    name_ja: "NFT",
    key: "NFT",
    sortKey: false,
  },
  {
    name_en: "-",
    name_ja: "価格",
    key: "Price",
    sortKey: true,
    sort: {
      type: "sort",
      option: "sort",
      value: "LOWEST_SELL",
    },
  },
  {
    name_en: "-",
    name_ja: "レア",
    key: "Rarity",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-TEXT",
    },
  },
  {
    name_en: "-",
    name_ja: "詳細",
    key: "Details",
    sortKey: false,
  },
];

const rarities = [
  {
    key: "COMMON",
    name_ja: "コモン",
    name_en: "-",
  },
  {
    key: "UNCOMMON",
    name_ja: "アンコモン",
    name_en: "-",
  },
  {
    key: "EPIC",
    name_ja: "エピック",
    name_en: "-",
  },
  {
    key: "LEGENDARY",
    name_ja: "レジェンダリー",
    name_en: "-",
  },
];

const siteUrl = "https://rarible.com";

function attributeVal(attr, key) {
  let value = null;
  for (let i = 0; i < attr.length; i++) {
    const o = attr[i];
    if (o.key === key) {
      value = o.value;
      break;
    }
  }
  return value;
}

const modules = {
  searchNfts,
  filterToObject,
  initialFilter,
  filterOptions,
  tableRows,
  siteUrl,
  rarities,
  attributeVal,
};

export default modules;
