import { Outlet } from "react-router-dom";
import Footer from "./footer";
import { Header } from "./header";

export default function Master(props) {

    return (<>
        <Header />
        <Outlet />
        <Footer />
    </>
    )
}