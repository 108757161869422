const initialState = {
  locale: "ja",
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case "LOCALE":
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
}
