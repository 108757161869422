import topImg1 from "../assets/images/top/screenshot_4525.png";
import topImg2 from "../assets/images/top/screenshot_4513 3.png";
import topImg38 from "../assets/images/top/camera 1.png";
import topImg4 from "../assets/images/top/screenshot_4524.png";
import topImg57 from "../assets/images/top/screenshot_4514 1.png";
import topImg6 from "../assets/images/top/screenshot_4513 3.png";

const raribleApiKey = process.env.REACT_APP_RARIBLE_API_KEY;
const snpitAddress = process.env.REACT_APP_SNPIT_ADDRESS;

const initialFilter = {
  size: 1000,
  filter: {
    deleted: false,
    collections: [`POLYGON:${snpitAddress}`],
    // traits: [
    //   {
    //     key: "Current Efficiency",
    //     value: "99",
    //   },
    // ],
    sellPlatforms: ["RARIBLE"],
  },
  //   traitSort: {
  //     order: "ASC",
  //     type: "NUMERIC",
  //     key: "Current Efficiency",
  //   },
};

async function searchNfts(payload = initialFilter) {
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "X-API-KEY": raribleApiKey,
    },
    body: JSON.stringify(payload),
  };

  return await fetch("https://api.rarible.org/v0.1/items/search", options)
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => console.error(err));
}

function filterToObject(str, value) {
  const parts = str.split("[");
  let currentObj = {};
  const filterKeys = ["type", "option", "key", "fieldType", "value"];

  parts.forEach((part, index) => {
    // eslint-disable-next-line
    const [key, _] = part.split("]");
    if (index > 0 && filterKeys.length >= index) {
      currentObj[filterKeys[index - 1]] = key;
    }
  });
  if (currentObj.fieldType === "key") {
    return null;
  }
  currentObj["value"] = value;

  return currentObj;
}

const filterOptions = [
  [
    {
      ul_class: "p-filter--genesis",
      items: [
        {
          type: "filter",
          option: "traits",
          key: "Genesis",
          value: "Yes",
          name_en: "-",
          name_ja: "ジェネシス",
          li_class: "",
        },
        {
          type: "filter",
          option: "traits",
          key: "Genesis",
          value: "No",
          name_en: "-",
          name_ja: "非ジェネシス",
          li_class: "",
        },
      ],
    },
    {
      ul_class: "p-filter--spec",
      items: [
        {
          key: "Current Quality",
          type: "sort",
          option: "traitSort",
          value: "DESC-NUMERIC",
          name_en: "-",
          name_ja: "Q特化",
          li_class: "",
        },
        {
          key: "Current Efficiency",
          type: "sort",
          option: "traitSort",
          value: "DESC-NUMERIC",
          name_en: "-",
          name_ja: "E特化",
          li_class: "",
        },
        {
          key: "Current Luck",
          type: "sort",
          option: "traitSort",
          value: "DESC-NUMERIC",
          name_en: "-",
          name_ja: "L特化",
          li_class: "",
        },
      ],
    },
  ],
  [
    {
      ul_class: "p-filter--unbox",
      items: [
        {
          type: "filter",
          option: "traitStatic",
          key: "Box",
          value: "Unbox",
          name_en: "-",
          name_ja: "Box",
          li_class: "p-filter--box",
        },
      ],
    },
    {
      ul_class: "p-filter--rarity p-filter--split",
      items: [
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "COMMON",
          name_en: "-",
          name_ja: "コモン",
          li_class: "p-filter--common",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "UNCOMMON",
          name_en: "-",
          name_ja: "アンコモン",
          li_class: "p-filter--uncommon",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "RARE",
          name_en: "-",
          name_ja: "レア",
          li_class: "p-filter--rare",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "EPIC",
          name_en: "-",
          name_ja: "エピック",
          li_class: "p-filter--epic",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "LEGENDARY",
          name_en: "-",
          name_ja: "レジェンダリー",
          li_class: "p-filter--legendary",
        },
        {
          type: "filter",
          option: "traits",
          key: "Rarity",
          value: "LIMITED",
          name_en: "-",
          name_ja: "リミテッド",
          li_class: "p-filter--limited",
        },
      ],
    },
  ],
];

const tableRows = [
  {
    name_en: "-",
    name_ja: "NFT",
    key: "NFT",
    sortKey: false,
    currentEffeciencyGenesisYes: 1,
    currentEffeciencyGenesisNo: 1,
    currentQualityGenesisYes: 1,
    currentQualityGenesisNo: 1,
    currentLuck: 1,
    unboxItem: true,
  },
  {
    name_en: "-",
    name_ja: "価格",
    key: "Price",
    sortKey: true,
    sort: {
      type: "sort",
      option: "sort",
      value: "LOWEST_SELL",
    },
    currentEffeciencyGenesisYes: 2,
    currentEffeciencyGenesisNo: 2,
    currentQualityGenesisYes: 2,
    currentQualityGenesisNo: 2,
    currentLuck: 2,
    unboxItem: true,
  },
  {
    name_en: "-",
    name_ja: "レア",
    key: "Rarity",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-TEXT",
    },
    currentEffeciencyGenesisYes: 3,
    currentEffeciencyGenesisNo: 3,
    currentQualityGenesisYes: 3,
    currentQualityGenesisNo: 3,
    currentLuck: 3,
    unboxItem: true,
  },
  {
    name_en: "-",
    name_ja: "画質",
    key: "Current Quality",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-NUMERIC",
    },
    currentEffeciencyGenesisYes: 7,
    currentEffeciencyGenesisNo: 9,
    currentQualityGenesisYes: 4,
    currentQualityGenesisNo: 4,
    currentLuck: 5,
  },
  {
    name_en: "-",
    name_ja: "効率",
    key: "Current Efficiency",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-NUMERIC",
    },
    currentEffeciencyGenesisYes: 4,
    currentEffeciencyGenesisNo: 4,
    currentQualityGenesisYes: 7,
    currentQualityGenesisNo: 8,
    currentLuck: 6,
  },
  {
    name_en: "-",
    name_ja: "運",
    key: "Current Luck",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-NUMERIC",
    },
    currentEffeciencyGenesisYes: 8,
    currentEffeciencyGenesisNo: 10,
    currentQualityGenesisYes: 8,
    currentQualityGenesisNo: 9,
    currentLuck: 4,
  },
  {
    name_en: "-",
    name_ja: "電池",
    key: "Current Battery",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-NUMERIC",
    },
    currentEffeciencyGenesisYes: 5,
    currentEffeciencyGenesisNo: 5,
    currentQualityGenesisYes: 9,
    currentQualityGenesisNo: 10,
    currentLuck: 7,
  },
  {
    name_en: "-",
    name_ja: "レベル",
    key: "Level",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-NUMERIC",
    },
    currentEffeciencyGenesisYes: 9,
    currentEffeciencyGenesisNo: 11,
    currentQualityGenesisYes: 10,
    currentQualityGenesisNo: 11,
    currentLuck: 8,
  },
  {
    name_en: "-",
    name_ja: "ミント",
    key: "Mint",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-TEXT",
    },
    currentEffeciencyGenesisYes: 6,
    currentEffeciencyGenesisNo: 6,
    currentQualityGenesisYes: 5,
    currentQualityGenesisNo: 5,
    currentLuck: 9,
  },
  {
    name_en: "-",
    name_ja: "撮影<br/>回数",
    key: "Total Shots",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-NUMERIC",
    },
    currentEffeciencyGenesisYes: 10,
    currentEffeciencyGenesisNo: 7,
    currentQualityGenesisYes: 6,
    currentQualityGenesisNo: 6,
    currentLuck: 10,
  },
  {
    name_en: "-",
    name_ja: "バッテ<br/>リー",
    key: "Battery Remaining",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-NUMERIC",
    },
    currentEffeciencyGenesisYes: 11,
    currentEffeciencyGenesisNo: 12,
    currentQualityGenesisYes: 11,
    currentQualityGenesisNo: 12,
    currentLuck: 11,
  },
  {
    name_en: "-",
    name_ja: "故障",
    key: "Condition",
    sortKey: true,
    sort: {
      type: "sort",
      option: "traitSort",
      value: "DESC-TEXT",
    },
    currentEffeciencyGenesisYes: 12,
    currentEffeciencyGenesisNo: 8,
    currentQualityGenesisYes: 12,
    currentQualityGenesisNo: 7,
    currentLuck: 12,
  },
  {
    name_en: "-",
    name_ja: "詳細",
    key: "Details",
    sortKey: false,
    currentEffeciencyGenesisYes: 99,
    currentEffeciencyGenesisNo: 99,
    currentQualityGenesisYes: 99,
    currentQualityGenesisNo: 99,
    currentLuck: 99,
    unboxItem: true,
  },
];

const rarities = [
  {
    key: "COMMON",
    name_ja: "コモン",
    name_en: "-",
  },
  {
    key: "UNCOMMON",
    name_ja: "アンコモン",
    name_en: "-",
  },
  {
    key: "EPIC",
    name_ja: "エピック",
    name_en: "-",
  },
  {
    key: "LEGENDARY",
    name_ja: "レジェンダリー",
    name_en: "-",
  },
  {
    key: "LIMITED",
    name_ja: "リミテッド",
    name_en: "-",
  },
];

const conditions = [
  {
    key: "Broken",
    name_ja: "あり​",
    name_en: "-",
  },
  {
    key: "Normal",
    name_ja: "無​",
    name_en: "-",
  },
];

const genesises = [
  {
    key: "Yes",
    name_ja: "ジェネシス​",
    name_en: "-",
  },
  {
    key: "No",
    name_ja: "非ジェネシス",
    name_en: "-",
  },
];

const topLists = [
  {
    image: topImg1,
    p1_name_ja: "E 特化",
    p2_name_ja: "ジェネシス",
    p3_name_ja: "U アンコモン",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-green-500 mt-2",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "Yes",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "UNCOMMON",
      },
      {
        key: "Current Efficiency",
        type: "filter",
        option: "traitRanges",
        value: "1-1000",
      },
      {
        key: "Current Efficiency",
        type: "sort",
        option: "traitSort",
        value: "DESC-NUMERIC",
      },
    ],
  },
  {
    image: topImg2,
    p1_name_ja: "E 特化",
    p2_name_ja: "非ジェネシス",
    p3_name_ja: "U アンコモン",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-green-500 mt-2",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "No",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "UNCOMMON",
      },
      {
        key: "Current Efficiency",
        type: "filter",
        option: "traitRanges",
        value: "1-1000",
      },
      {
        key: "Current Efficiency",
        type: "sort",
        option: "traitSort",
        value: "DESC-NUMERIC",
      },
    ],
  },
  {
    image: topImg38,
    p1_name_ja: "E 特化",
    p2_name_ja: "ジェネシス",
    p3_name_ja: "C コモン",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-green-500 mt-2",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "Yes",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "COMMON",
      },
      {
        key: "Current Efficiency",
        type: "filter",
        option: "traitRanges",
        value: "1-1000",
      },
      {
        key: "Current Efficiency",
        type: "sort",
        option: "traitSort",
        value: "DESC-NUMERIC",
      },
    ],
  },
  {
    image: topImg4,
    p1_name_ja: "E 特化",
    p2_name_ja: "非ジェネシス",
    p3_name_ja: "C コモン",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-green-500 mt-2",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "No",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "COMMON",
      },
      {
        key: "Current Efficiency",
        type: "filter",
        option: "traitRanges",
        value: "1-1000",
      },
      {
        key: "Current Efficiency",
        type: "sort",
        option: "traitSort",
        value: "DESC-NUMERIC",
      },
    ],
  },
  {
    image: topImg57,
    p1_name_ja: "Q 特化",
    p2_name_ja: "91",
    p3_name_ja: "ジェネシス",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class: "text-white text-2xl",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "Yes",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "COMMON",
      },
      {
        key: "Current Quality",
        type: "filter",
        option: "traitRanges",
        value: "91-1000",
      },
    ],
  },
  {
    image: topImg6,
    p1_name_ja: "Q 特化",
    p2_name_ja: "91",
    p3_name_ja: "非ジェネシス",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class: "text-white text-2xl",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "No",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "COMMON",
      },
      {
        key: "Current Quality",
        type: "filter",
        option: "traitRanges",
        value: "91-1000",
      },
    ],
  },
  {
    image: topImg57,
    p1_name_ja: "Q 特化",
    p2_name_ja: "81",
    p3_name_ja: "ジェネシス",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class: "text-white text-2xl",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "Yes",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "COMMON",
      },
      {
        key: "Current Quality",
        type: "filter",
        option: "traitRanges",
        value: "81-1000",
      },
    ],
  },
  {
    image: topImg38,
    p1_name_ja: "Q 特化",
    p2_name_ja: "81",
    p3_name_ja: "非ジェネシス",
    p1_name_en: "",
    p2_name_en: "",
    p3_name_en: "",
    p1_class: "text-white text-2xl",
    p2_class: "text-white text-2xl",
    p3_class:
      "text-white rounded-full px-3 py-1 font-[600] text-[10px] text-nowrap tag bg-yellow-500 mt-2",
    filters: [
      {
        key: "Genesis",
        type: "filter",
        option: "traits",
        value: "No",
      },
      {
        key: "Rarity",
        type: "filter",
        option: "traits",
        value: "COMMON",
      },
      {
        key: "Current Quality",
        type: "filter",
        option: "traitRanges",
        value: "81-1000",
      },
    ],
  },
];

const currencyList = [
  {
    currencyId: "ETHEREUM:0x6b175474e89094c44da98b954eedeac495271d0f",
    symbol: "dai",
    abbreviation: "dai",
    rate: "1.0004138081718699",
  },
  {
    currencyId: "IMMUTABLEX:0x6b175474e89094c44da98b954eedeac495271d0f",
    symbol: "dai",
    abbreviation: "dai",
    rate: "1.0004138081718699",
  },
  {
    currencyId: "PALM:0x4c1f6fcbd233241bf2f4d02811e3bf8429bc27b8",
    symbol: "dai",
    abbreviation: "dai",
    rate: "1.0004138081718699",
  },
  {
    currencyId: "POLYGON:0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    symbol: "dai",
    abbreviation: "dai",
    rate: "1.0004138081718699",
  },
  {
    currencyId: "ARBITRUM:0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "ASTARZKEVM:0xe9cc37904875b459fa5d0fe37680d36f1ed55e38",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "BASE:0x4200000000000000000000000000000000000006",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "CELO:0x66803fb87abd4aac3cbb3fad7c3aa01f6f3fb207",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "ETHEREUM:0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "IMMUTABLEX:0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "KROMA:0x4200000000000000000000000000000000000001",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "LIGHTLINK:0x7ebef2a4b1b09381ec5b9df8c5c6f2dbeca59c73",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "MANTLE:0xdeaddeaddeaddeaddeaddeaddeaddeaddead1111",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "MATCH:0x11de762da8abf6e666f182f5ef1fab6102b7a774",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "MOONBEAM:0xab3f0245b83feb11d15aaffefd7ad465a59817ed",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "OASYS:0x5801e5a61164024be2554248e33127c6ebc8c113",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "PALM:0x726138359c17f1e56ba8c4f737a7caf724f6010b",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "POLYGON:0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "RARI:0xf037540e51d71b2d2b1120e8432ba49f29edfbd0",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "XAI:0xbee82cfdaff4a6aa4e4793cb81eb1c2e79ac463c",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "ZKSYNC:0x5aea5775959fbc2557cc8789bc1bf90a239d9a91",
    symbol: "weth",
    abbreviation: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "ETHEREUM:0xfca59cd816ab1ead66534d82bc21e7515ce441cf",
    symbol: "rarible",
    abbreviation: "rari",
    rate: "1.5400332890791322",
  },
  {
    currencyId: "IMMUTABLEX:0xfca59cd816ab1ead66534d82bc21e7515ce441cf",
    symbol: "rarible",
    abbreviation: "rari",
    rate: "1.5400332890791322",
  },
  {
    currencyId: "ETHEREUM:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    symbol: "usd-coin",
    abbreviation: "usdc",
    rate: "1.0003553378019474",
  },
  {
    currencyId: "FLOW:A.b19436aae4d94622.FiatToken",
    symbol: "usd-coin",
    abbreviation: "usdc",
    rate: "1.0003553378019474",
  },
  {
    currencyId: "IMMUTABLEX:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    symbol: "usd-coin",
    abbreviation: "usdc",
    rate: "1.0003553378019474",
  },
  {
    currencyId: "MOONBEAM:0x931715fee2d06333043d11f658c8ce934ac61d0c",
    symbol: "usd-coin",
    abbreviation: "usdc",
    rate: "1.0003553378019474",
  },
  {
    currencyId: "POLYGON:0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    symbol: "usd-coin",
    abbreviation: "usdc",
    rate: "1.0003553378019474",
  },
  {
    currencyId: "POLYGON:0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    symbol: "bridged-usdc-polygon-pos-bridge",
    abbreviation: "usdc.e",
    rate: "1.001147286317673",
  },
  {
    currencyId: "ETHEREUM:0x361887c1d1b73557018c47c8001711168128cf69",
    symbol: "firdaos",
    abbreviation: "fdo",
    rate: "0.12297499901222021",
  },
  {
    currencyId: "IMMUTABLEX:0x361887c1d1b73557018c47c8001711168128cf69",
    symbol: "firdaos",
    abbreviation: "fdo",
    rate: "0.12297499901222021",
  },
  {
    currencyId: "ETHEREUM:0xdacd69347de42babfaecd09dc88958378780fb62",
    symbol: "atari",
    abbreviation: "atri",
    rate: "0.00016272413820696364",
  },
  {
    currencyId: "IMMUTABLEX:0xdacd69347de42babfaecd09dc88958378780fb62",
    symbol: "atari",
    abbreviation: "atri",
    rate: "0.00016272413820696364",
  },
  {
    currencyId: "ETHEREUM:0xab7aaf9e485a3bc885985184abe9fc6aba727bd6",
    symbol: "many",
    abbreviation: "many",
    rate: "0.02260136556338436",
  },
  {
    currencyId: "IMMUTABLEX:0xab7aaf9e485a3bc885985184abe9fc6aba727bd6",
    symbol: "many",
    abbreviation: "many",
    rate: "0.02260136556338436",
  },
  {
    currencyId: "ETHEREUM:0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988",
    symbol: "terra-virtua-kolect",
    abbreviation: "tvk",
    rate: "0.035567510988262836",
  },
  {
    currencyId: "IMMUTABLEX:0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988",
    symbol: "terra-virtua-kolect",
    abbreviation: "tvk",
    rate: "0.035567510988262836",
  },
  {
    currencyId: "ETHEREUM:0x1bbc8aa095f5ca72dfbc169773989e41de8bdb86",
    symbol: "donkey",
    abbreviation: "donk",
    rate: "0.0014683979050203863",
  },
  {
    currencyId: "IMMUTABLEX:0x1bbc8aa095f5ca72dfbc169773989e41de8bdb86",
    symbol: "donkey",
    abbreviation: "donk",
    rate: "0.0014683979050203863",
  },
  {
    currencyId: "ETHEREUM:0xa9248f8e40d4b9c3ca8ebd8e07e9bcb942c616d8",
    symbol: "arke",
    abbreviation: "arke",
    rate: "0.0171081852208944",
  },
  {
    currencyId: "IMMUTABLEX:0xa9248f8e40d4b9c3ca8ebd8e07e9bcb942c616d8",
    symbol: "arke",
    abbreviation: "arke",
    rate: "0.0171081852208944",
  },
  {
    currencyId: "ETHEREUM:0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7",
    symbol: "reflect-finance",
    abbreviation: "rfi",
    rate: "0.010909335458441461",
  },
  {
    currencyId: "IMMUTABLEX:0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7",
    symbol: "reflect-finance",
    abbreviation: "rfi",
    rate: "0.010909335458441461",
  },
  {
    currencyId: "ETHEREUM:0x98c36c0e953463bd5146c8783ce081ce1d187acf",
    symbol: "polyient-games-unity",
    abbreviation: "pgu",
    rate: "0.30983497915514",
  },
  {
    currencyId: "IMMUTABLEX:0x98c36c0e953463bd5146c8783ce081ce1d187acf",
    symbol: "polyient-games-unity",
    abbreviation: "pgu",
    rate: "0.30983497915514",
  },
  {
    currencyId: "ETHEREUM:0xd04785c4d8195e4a54d9dec3a9043872875ae9e2",
    symbol: "rotten",
    abbreviation: "rot",
    rate: "0.000034953605857027235",
  },
  {
    currencyId: "IMMUTABLEX:0xd04785c4d8195e4a54d9dec3a9043872875ae9e2",
    symbol: "rotten",
    abbreviation: "rot",
    rate: "0.000034953605857027235",
  },
  {
    currencyId: "ETHEREUM:0xdcfe18bc46f5a0cd0d3af0c2155d2bcb5ade2fc5",
    symbol: "hue",
    abbreviation: "hue",
    rate: "0.030823803058530542",
  },
  {
    currencyId: "IMMUTABLEX:0xdcfe18bc46f5a0cd0d3af0c2155d2bcb5ade2fc5",
    symbol: "hue",
    abbreviation: "hue",
    rate: "0.030823803058530542",
  },
  {
    currencyId: "ETHEREUM:0x740623d2c797b7d8d1ecb98e9b4afcf99ec31e14",
    symbol: "dynamite",
    abbreviation: "dyt",
    rate: "0.018487770049266484",
  },
  {
    currencyId: "IMMUTABLEX:0x740623d2c797b7d8d1ecb98e9b4afcf99ec31e14",
    symbol: "dynamite",
    abbreviation: "dyt",
    rate: "0.018487770049266484",
  },
  {
    currencyId: "ETHEREUM:0x88dafebb769311d7fbbeb9a21431fa026d4100d0",
    symbol: "lux-expression",
    abbreviation: "lux",
    rate: "0.03449440038111356",
  },
  {
    currencyId: "IMMUTABLEX:0x88dafebb769311d7fbbeb9a21431fa026d4100d0",
    symbol: "lux-expression",
    abbreviation: "lux",
    rate: "0.03449440038111356",
  },
  {
    currencyId: "ETHEREUM:0x3aa5f749d4a6bcf67dac1091ceb69d1f5d86fa53",
    symbol: "deflect",
    abbreviation: "deflct",
    rate: "1.3654327485380366",
  },
  {
    currencyId: "IMMUTABLEX:0x3aa5f749d4a6bcf67dac1091ceb69d1f5d86fa53",
    symbol: "deflect",
    abbreviation: "deflct",
    rate: "1.3654327485380366",
  },
  {
    currencyId: "ETHEREUM:0x64d91f12ece7362f91a6f8e7940cd55f05060b92",
    symbol: "ash",
    abbreviation: "ash",
    rate: "1.3083169677317381",
  },
  {
    currencyId: "IMMUTABLEX:0x64d91f12ece7362f91a6f8e7940cd55f05060b92",
    symbol: "ash",
    abbreviation: "ash",
    rate: "1.3083169677317381",
  },
  {
    currencyId: "ETHEREUM:0xdf801468a808a32656d2ed2d2d80b72a129739f4",
    symbol: "cube",
    abbreviation: "cube",
    rate: "0.03223265186294514",
  },
  {
    currencyId: "IMMUTABLEX:0xdf801468a808a32656d2ed2d2d80b72a129739f4",
    symbol: "cube",
    abbreviation: "cube",
    rate: "0.03223265186294514",
  },
  {
    currencyId: "FLOW:A.1654653399040a61.FlowToken",
    symbol: "flow",
    abbreviation: "flow",
    rate: "0.5375135551845045",
  },
  {
    currencyId: "FLOW:A.3c5959b568896393.FUSD",
    symbol: "flowusd",
    abbreviation: "fusd",
    alias: "usd",
    rate: "1",
  },
  {
    currencyId: "ETHERLINK:0x0000000000000000000000000000000000000000",
    symbol: "tezos",
    abbreviation: "xtz",
    rate: "0.6792341248374929",
  },
  {
    currencyId: "TEZOS:tz1Ke2h7sDdakHJQh8WX4Z372du1KChsksyU",
    symbol: "tezos",
    abbreviation: "xtz",
    rate: "0.6792341248374929",
  },
  {
    currencyId: "TEZOS:KT1JBNFcB5tiycHNdYGYCtR3kk6JaJysUCi8:0",
    symbol: "stasis-eurs",
    abbreviation: "eurs",
    rate: "1.092270098990692",
  },
  {
    currencyId: "ETHEREUM:0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    symbol: "matic-network",
    abbreviation: "matic",
    rate: "0.4118443718893583",
  },
  {
    currencyId: "IMMUTABLEX:0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    symbol: "matic-network",
    abbreviation: "matic",
    rate: "0.4118443718893583",
  },
  {
    currencyId: "POLYGON:0x0000000000000000000000000000000000000000",
    symbol: "matic-network",
    abbreviation: "matic",
    rate: "0.4118443718893583",
  },
  {
    currencyId: "POLYGON:0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    symbol: "wmatic",
    abbreviation: "wmatic",
    rate: "0.41111907157039995",
  },
  {
    currencyId: "ETHERLINK:0xc9b53ab2679f573e480d01e0f49e2b5cfb7a3eab",
    symbol: "wtez",
    abbreviation: "wxtz",
    alias: "tezos",
    rate: "0.6792341248374929",
  },
  {
    currencyId: "TEZOS:KT1EJkjatSNWD2NiPx8hivKnawxuyaVTwP6n",
    symbol: "wtez",
    abbreviation: "wxtz",
    alias: "tezos",
    rate: "0.6792341248374929",
  },
  {
    currencyId: "TEZOS:KT1K9gCRgaLRFKTErYt1wVxA3Frb9FjasjTV",
    symbol: "kolibri-usd",
    abbreviation: "kusd",
    alias: "usd",
    rate: "1",
  },
  {
    currencyId: "TEZOS:KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9",
    symbol: "usdtez",
    abbreviation: "usdtz",
    alias: "usd",
    rate: "1",
  },
  {
    currencyId: "TEZOS:KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ:17",
    symbol: "wrapped-usdc",
    abbreviation: "xusd",
    alias: "usd",
    rate: "1",
  },
  {
    currencyId: "TEZOS:KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b:0",
    symbol: "plenty-dao",
    abbreviation: "plenty",
    rate: "0.008402713154773122",
  },
  {
    currencyId: "TEZOS:KT1XRPEPXbZK25r3Htzp2o1x7xdMMmfocKNW:0",
    symbol: "youves-uusd",
    abbreviation: "uusd",
    alias: "usd",
    rate: "1",
  },
  {
    currencyId: "TEZOS:KT1JBNFcB5tiycHNdYGYCtR3kk6JaJysUCi8:0",
    symbol: "eurl",
    abbreviation: "eurl",
    alias: "seur",
    rate: "1.0879357524387248",
  },
  {
    currencyId: "SOLANA:So11111111111111111111111111111111111111112",
    symbol: "solana",
    abbreviation: "sol",
    rate: "153.77066542036445",
  },
  {
    currencyId: "ETHEREUM:0x275f5ad03be0fa221b4c6649b8aee09a42d9412a",
    symbol: "monavale",
    abbreviation: "mona",
    rate: "74.8683777671637",
  },
  {
    currencyId: "IMMUTABLEX:0x275f5ad03be0fa221b4c6649b8aee09a42d9412a",
    symbol: "monavale",
    abbreviation: "mona",
    rate: "74.8683777671637",
  },
  {
    currencyId: "POLYGON:0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    symbol: "monavale",
    abbreviation: "mona",
    rate: "74.8683777671637",
  },
  {
    currencyId: "ETHEREUM:0x4d224452801aced8b2f0aebe155379bb5d594381",
    symbol: "apecoin",
    abbreviation: "ape",
    rate: "0.5975727512753309",
  },
  {
    currencyId: "IMMUTABLEX:0x4d224452801aced8b2f0aebe155379bb5d594381",
    symbol: "apecoin",
    abbreviation: "ape",
    rate: "0.5975727512753309",
  },
  {
    currencyId: "POLYGON:0x2f3e306d9f02ee8e8850f9040404918d0b345207",
    symbol: "dogami",
    abbreviation: "doga",
    rate: "0.004509323340512922",
  },
  {
    currencyId: "TEZOS:KT1Ha4yFVeyzw6KRAdkzq6TxDHB97KG4pZe8",
    symbol: "dogami",
    abbreviation: "doga",
    rate: "0.004509323340512922",
  },
  {
    currencyId: "ETHEREUM:0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
    symbol: "immutable-x",
    abbreviation: "imx",
    rate: "1.1689156200927444",
  },
  {
    currencyId: "IMMUTABLEX:0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
    symbol: "immutable-x",
    abbreviation: "imx",
    rate: "1.1689156200927444",
  },
  {
    currencyId: "IMMUTABLEX:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    symbol: "imx-usdc",
    abbreviation: "usdc",
    alias: "usd",
    rate: "1",
  },
  {
    currencyId: "IMMUTABLEX:0xed35af169af46a02ee13b9d79eb57d6d68c1749e",
    symbol: "ecomi",
    abbreviation: "omi",
    rate: "0.00043371449402299686",
  },
  {
    currencyId: "IMMUTABLEX:0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62",
    symbol: "guild-of-guardians",
    abbreviation: "gog",
    rate: "0.05319315673381009",
  },
  {
    currencyId: "IMMUTABLEX:0xccc8cb5229b0ac8069c51fd58367fd1e622afd97",
    symbol: "gods-unchained",
    abbreviation: "gods",
    rate: "0.218821522894399",
  },
  {
    currencyId: "ETHEREUM:0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
    symbol: "decentraland",
    abbreviation: "mana",
    rate: "0.26916102103900047",
  },
  {
    currencyId: "IMMUTABLEX:0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
    symbol: "decentraland",
    abbreviation: "mana",
    rate: "0.26916102103900047",
  },
  {
    currencyId: "ETHEREUM:0x5283d291dbcf85356a21ba090e6db59121208b44",
    symbol: "blur",
    abbreviation: "blur",
    rate: "0.14281833248455308",
  },
  {
    currencyId: "IMMUTABLEX:0x5283d291dbcf85356a21ba090e6db59121208b44",
    symbol: "blur",
    abbreviation: "blur",
    rate: "0.14281833248455308",
  },
  {
    currencyId: "ETHEREUM:0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
    symbol: "the-sandbox",
    abbreviation: "sand",
    rate: "0.2511574297785484",
  },
  {
    currencyId: "IMMUTABLEX:0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
    symbol: "the-sandbox",
    abbreviation: "sand",
    rate: "0.2511574297785484",
  },
  {
    currencyId: "POLYGON:0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    symbol: "the-sandbox",
    abbreviation: "sand",
    rate: "0.2511574297785484",
  },
  {
    currencyId: "ETHEREUM:0x8642A849D0dcb7a15a974794668ADcfbe4794B56",
    symbol: "prosper",
    abbreviation: "pros",
    rate: "0.295103036540002",
  },
  {
    currencyId: "IMMUTABLEX:0x8642A849D0dcb7a15a974794668ADcfbe4794B56",
    symbol: "prosper",
    abbreviation: "pros",
    rate: "0.295103036540002",
  },
  {
    currencyId: "ETHEREUM:0x557b933a7c2c45672b610f8954a3deb39a51a8ca",
    symbol: "revv",
    abbreviation: "revv",
    rate: "0.005186433868293829",
  },
  {
    currencyId: "IMMUTABLEX:0x557b933a7c2c45672b610f8954a3deb39a51a8ca",
    symbol: "revv",
    abbreviation: "revv",
    rate: "0.005186433868293829",
  },
  {
    currencyId: "ETHEREUM:0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373",
    symbol: "gamee",
    abbreviation: "gmee",
    rate: "0.018455513231778898",
  },
  {
    currencyId: "IMMUTABLEX:0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373",
    symbol: "gamee",
    abbreviation: "gmee",
    rate: "0.018455513231778898",
  },
  {
    currencyId: "ETHEREUM:0x0000000000a39bb272e79075ade125fd351887ac",
    symbol: "blur-pool",
    abbreviation: "weth",
    alias: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "IMMUTABLEX:0x0000000000a39bb272e79075ade125fd351887ac",
    symbol: "blur-pool",
    abbreviation: "weth",
    alias: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "MANTLE:0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8",
    symbol: "wmnt",
    abbreviation: "wmnt",
    alias: "mantle",
    rate: "0.6225699242589736",
  },
  {
    currencyId: "ARBITRUM:0x912ce59144191c1204e64559fe8253a0e49e6548",
    symbol: "arbitrum",
    abbreviation: "arb",
    rate: "0.5193915438052132",
  },
  {
    currencyId: "CHILIZ:0x677f7e16c7dd57be1d4c8ad1244883214953dc47",
    symbol: "wchz",
    abbreviation: "wchz",
    alias: "chiliz",
    rate: "0.05390940252343946",
  },
  {
    currencyId: "ETHEREUM:0xbb24c7a8e89eda9f22842644df8d0524871257e5",
    symbol: "lightlink",
    abbreviation: "ll",
    rate: "0.01947328647912911",
  },
  {
    currencyId: "IMMUTABLEX:0xbb24c7a8e89eda9f22842644df8d0524871257e5",
    symbol: "lightlink",
    abbreviation: "ll",
    rate: "0.01947328647912911",
  },
  {
    currencyId: "LIGHTLINK:0xd9d7123552fa2bedb2348bb562576d67f6e8e96e",
    symbol: "lightlink",
    abbreviation: "ll",
    rate: "0.01947328647912911",
  },
  {
    currencyId: "ETHEREUM:0xea068fba19ce95f12d252ad8cb2939225c4ea02d",
    symbol: "fief",
    rate: "0.017250226914371482",
  },
  {
    currencyId: "FIEF:0x0000000000000000000000000000000000000000",
    symbol: "fief",
    rate: "0.017250226914371482",
  },
  {
    currencyId: "IMMUTABLEX:0xea068fba19ce95f12d252ad8cb2939225c4ea02d",
    symbol: "fief",
    rate: "0.017250226914371482",
  },
  {
    currencyId: "XAI:0x0000000000000000000000000000000000000000",
    symbol: "xai",
    abbreviation: "xai",
    rate: "1.003469468880753",
  },
  {
    currencyId: "CELO:0x471ece3750da237f93b8e339c536989b8978a438",
    symbol: "wcelo",
    alias: "celo",
    rate: "0.44745030679043535",
  },
  {
    currencyId: "XAI:0x3fb787101dc6be47cfe18aeee15404dcc842e6af",
    symbol: "wxai",
    abbreviation: "wxai",
    alias: "xai",
    rate: "1.003469468880753",
  },
  {
    currencyId: "BASE:0x4ed4e862860bed51a9570b96d89af5e1b0efefed",
    symbol: "degen-base",
    rate: "0.0033926884460344637",
  },
  {
    currencyId: "BASE:0x6921b130d297cc43754afba22e5eac0fbf8db75b",
    symbol: "doginme",
    rate: "0.00010994439467260703",
  },
  {
    currencyId: "BASE:0xf6e932ca12afa26665dc4dde7e27be02a7c02e50",
    symbol: "mochi-thecatcoin",
    rate: "0.000008978134958991952",
  },
  {
    currencyId: "BASE:0xac1bd2486aaf3b5c0fc3fd868558b082a531b2b4",
    symbol: "toshi",
    rate: "0.00011022720729071252",
  },
  {
    currencyId: "BASE:0x532f27101965dd16442e59d40670faf5ebb142e4",
    symbol: "based-brett",
    rate: "0.09901481245704882",
  },
  {
    currencyId: "BASE:0x7f12d13b34f5f4f0a9449c16bcd42f0da47af200",
    symbol: "normie-2",
    rate: "0.006105773612139136",
  },
  {
    currencyId: "BASE:0x0d97f261b1e88845184f678e2d1e7a98d9fd38de",
    symbol: "base-god",
    rate: "0.00003813871442858606",
  },
  {
    currencyId: "BASE:0x8c81b4c816d66d36c4bf348bdec01dbcbc70e987",
    symbol: "briun-armstrung",
    rate: "0.0003839273669546605",
  },
  {
    currencyId: "BASE:0xe3086852a4b125803c815a158249ae468a3254ca",
    symbol: "mfercoin",
    rate: "0.012469180423963559",
  },
  {
    currencyId: "PALM:0xf98cabf0a963452c5536330408b2590567611a71",
    symbol: "wpalm",
    abbreviation: "wpalm",
    alias: "palm",
  },
  {
    currencyId: "OASYS:0x5200000000000000000000000000000000000001",
    symbol: "woasys",
    abbreviation: "woas",
    alias: "oasys",
    rate: "0.030803519466854456",
  },
  {
    currencyId: "SAAKURU:0x557a526472372f1f222ecc6af8818c1e6e78a85f",
    symbol: "woasys",
    abbreviation: "woas",
    alias: "oasys",
    rate: "0.030803519466854456",
  },
  {
    currencyId: "LISK:0xac485391eb2d7d88253a7f1ef18c37f4242d1a24",
    symbol: "lisk",
    abbreviation: "lisk",
    rate: "0.8179896370381297",
  },
  {
    currencyId: "OASIS:0x21c718c22d52d0f3a789b752d4c2fd5908a8a733",
    symbol: "wrose",
    abbreviation: "wrose",
    alias: "rose",
    rate: "0.0006432674944993875",
  },
  {
    currencyId: "SEI:0xe30fedd158a2e3b13e9badaeabafc5516e95e8c7",
    symbol: "wsei",
    abbreviation: "wsei",
    alias: "sei",
  },
  {
    currencyId: "MOONBEAM:0xacc15dc74880c9944775448304b263d191c6077f",
    symbol: "wglmr",
    alias: "moonbeam",
    rate: "0.16087503507701145",
  },
  {
    currencyId: "KROMA:0x61e0d34b5206fa8005ec1de8000df9b9ddee23db",
    symbol: "speth",
    abbreviation: "speth",
    alias: "weth",
    rate: "2533.945492206571",
  },
  {
    currencyId: "MOONBEAM:0xffffffff7d2b0b761af01ca8e25242976ac0ad7d",
    symbol: "xcusdc",
    alias: "usd",
    rate: "1",
  },
  {
    currencyId: "ARBITRUM:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "ASTARZKEVM:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "BASE:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "CAMP:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "ETHEREUM:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "IMMUTABLEX:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "KROMA:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "LIGHTLINK:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "LISK:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "RARI:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "ZKCANDY:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "ZKLINK:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "ZKSYNC:0x0000000000000000000000000000000000000000",
    symbol: "ethereum",
    abbreviation: "eth",
    rate: "2528.3127507918934",
  },
  {
    currencyId: "MANTLE:0x0000000000000000000000000000000000000000",
    symbol: "mantle",
    abbreviation: "mnt",
    rate: "0.6225699242589736",
  },
  {
    currencyId: "CHILIZ:0x0000000000000000000000000000000000000000",
    symbol: "chiliz",
    abbreviation: "chz",
    rate: "0.05390940252343946",
  },
  {
    currencyId: "CELO:0x0000000000000000000000000000000000000000",
    symbol: "celo",
    rate: "0.44745030679043535",
  },
  {
    currencyId: "OASYS:0x0000000000000000000000000000000000000000",
    symbol: "oasys",
    abbreviation: "oas",
    rate: "0.030803519466854456",
  },
  {
    currencyId: "SAAKURU:0x0000000000000000000000000000000000000000",
    symbol: "oasys",
    abbreviation: "oas",
    rate: "0.030803519466854456",
  },
  {
    currencyId: "OASIS:0x0000000000000000000000000000000000000000",
    symbol: "rose",
    abbreviation: "rose",
    rate: "0.0006432674944993875",
  },
  {
    currencyId: "MATCH:0x0000000000000000000000000000000000000000",
    symbol: "binancecoin",
    abbreviation: "bnb",
    rate: "494.94803572118747",
  },
  {
    currencyId: "PALM:0x0000000000000000000000000000000000000000",
    symbol: "palm",
    abbreviation: "palm",
  },
  {
    currencyId: "FIVIRE:0x0000000000000000000000000000000000000000",
    symbol: "5vire",
    abbreviation: "5vire",
  },
  {
    currencyId: "SEI:0x0000000000000000000000000000000000000000",
    symbol: "sei",
    abbreviation: "sei",
  },
  {
    currencyId: "MOONBEAM:0x0000000000000000000000000000000000000000",
    symbol: "moonbeam",
    abbreviation: "glmr",
    rate: "0.16087503507701145",
  },
  {
    currencyId: "APTOS:MHgxOjphcHRvc19jb2luOjpBcHRvc0NvaW4=",
    symbol: "aptos",
    abbreviation: "apt",
    alias: "aptos",
    rate: "5.302360777253492",
  },
];

function _currencies() {
  let obj = {};
  for (let i = 0; i < currencyList.length; i++) {
    const c = currencyList[i];
    obj[c.currencyId] = c.symbol.toUpperCase();
  }
  return obj;
}

const currencies = _currencies();

const siteUrl = "https://rarible.com";

function attributeVal(attr, key) {
  let value = null;
  for (let i = 0; i < attr.length; i++) {
    const o = attr[i];
    if (o.key === key) {
      value = o.value;
      break;
    }
  }
  return value;
}

const sortAttributes = async (items, sort, order) => {
  if (order === "LOWEST_SELL") {
    return await items.sort(
      (
        { bestSellOrder: { makePriceUsd: a } },
        { bestSellOrder: { makePriceUsd: b } }
      ) => {
        if (a === null) return 1;
        if (b === null) return -1;
        return Number(a) - Number(b);
      }
    );
  } else if (order === "HIGHEST_SELL") {
    return await items.sort(
      (
        { bestSellOrder: { makePriceUsd: a } },
        { bestSellOrder: { makePriceUsd: b } }
      ) => {
        if (a === null) return -1;
        if (b === null) return 1;
        return Number(b) - Number(a);
      }
    );
  } else if (sort.key === "Mint") {
    return await items.sort(
      ({ meta: { attributes: aAtt } }, { meta: { attributes: bAtt } }) => {
        const a = attributeVal(aAtt, sort.key);
        const b = attributeVal(bAtt, sort.key);
        if (sort.order === "DESC") {
          if (b === null) return -1;
          if (a === null) return 1;
          return a.localeCompare(b);
        } else {
          if (b === null) return -1;
          if (a === null) return 1;
          return b.localeCompare(a);
        }
      }
    );
  } else if (sort.order === "DESC") {
    return await items.sort(
      ({ meta: { attributes: aAtt } }, { meta: { attributes: bAtt } }) => {
        const a = attributeVal(aAtt, sort.key);
        const b = attributeVal(bAtt, sort.key);
        if (a === null) return -1;
        if (b === null) return 1;
        return b.localeCompare(a);
      }
    );
  } else if (sort.order === "ASC") {
    return await items.sort(
      ({ meta: { attributes: aAtt } }, { meta: { attributes: bAtt } }) => {
        const a = attributeVal(aAtt, sort.key);
        const b = attributeVal(bAtt, sort.key);
        if (a === null) return 1;
        if (b === null) return -1;
        return a.localeCompare(b);
      }
    );
  }
  return items;
};

// hide unbox items
const hideUnboxItems = async (items) => {
  return await items.filter(({ meta: { attributes } }) =>
    attributeVal(attributes, "Mint")
  );
};

const unboxItems = async (items) => {
  return await items.filter(
    ({ meta: { attributes } }) => !attributeVal(attributes, "Mint")
  );
};

const filterItems = async (items, name, value) => {
  return await items.filter(
    ({ meta: { attributes } }) => attributeVal(attributes, name) === value
  );
};

const modules = {
  searchNfts,
  filterToObject,
  initialFilter,
  filterOptions,
  tableRows,
  siteUrl,
  rarities,
  conditions,
  genesises,
  topLists,
  currencies,
  sortAttributes,
  hideUnboxItems,
  attributeVal,
  unboxItems,
  filterItems,
};
export default modules;
